import React from "react";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ACTIVE_PROMOCODE_STATUS } from "../data/constants/statuses";
import { NEW_PROMOCODE_STATUS } from '../data/constants/statuses';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    padding: '6px 24px 6px 16px'
  },
  button: {
    margin: theme.spacing(0),
    padding: 2,
    minWidth: 20
  },
  disabled: {
    // backgroundColor: '#f1f1f1',
    color: '#777'
  },
  totalSumStyle: {
    backgroundColor: '#e0f0ff',
    color: '#000',
    fontWeight: 700
  }
}));

const AdminPromocodeBonusReadRow = ({ item, itemId }) => {

  const classes = useStyles();

  const disabledRow = item.activated === true ? classes.disabled : ''

  return (
    <TableRow key={itemId} className={disabledRow}>
      <TableCell className={[classes.tableCell, disabledRow]}>
        {item.manager ? item.manager.managerName : 'empty'}
      </TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>
        {item.promocode}
      </TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>{item.doctor}</TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>{item.clinic}</TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>{item.city}</TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>{item.region}</TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>{item.phone}</TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>{item.cardNumber}</TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>{item.bonusMonth}</TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>
        {item.bonusSum}
      </TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>
        {item.bonusExtra}
      </TableCell>
      <TableCell className={[classes.tableCell, classes.totalSumStyle]}>
        {Number(item.bonusSum) + Number(item.bonusExtra)}
      </TableCell>
    </TableRow>
  );
};

export default AdminPromocodeBonusReadRow;