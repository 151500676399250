import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { updatePromocodeItemAction } from './../data/actions/promocodeActions';
import { ACTIVE_PROMOCODE_STATUS, NEW_PROMOCODE_STATUS } from './../data/constants/statuses';
import PromocodeEditableRow from './PromocodeEditableRow';
import PromocodeReadRow from './PromocodeReadRow';
import AdminPromocodeReadRow from './AdminPromocodeReadRow';

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
    position: 'relative',
    top: -11
  },
  tableCell: {
    padding: '6px 8px 6px 8px'
  },
  container: {
    maxHeight: 1000,
    padding: '0 0 10px',
    overflow: 'visible',
    marginTop: 122
  },
  stickyHeader: {
    backgroundColor: '#e8ebf7',
    position: "sticky",
    top: 112,
    zIndex: 100,
    boxShadow: '1px 1px 1px #ccc'
  },
  formControl: {
    width: 140,
    marginLeft: 10
  },
  filterRow: {
    backgroundColor: '#e8ebf7',
    width: '100%',
    display: 'inline-block',
    position: 'fixed',
    top: 64,
    zIndex: 101,
    borderBottom: '1px solid #ccc'
  },
});

const AdminEditTablePromocodeList = (props) => {
  const {
    items,
    adminData,
    status,
    managerId,
    managerList,
    location,
    setLocation,
    handleChangeManager
  } = props

  const classes = useStyles();

  const dispatch = useDispatch()

  // const [editFormData, setEditFormData] = useState({
  // });

  // const [editItemId, setEditItemId] = useState(null);

  // const handleEditFormChange = (event) => {
  //   event.preventDefault();

  //   const fieldName = event.target.getAttribute("name");
  //   const fieldValue = event.target.value;

  //   const newFormData = { ...editFormData };
  //   newFormData[fieldName] = fieldValue;

  //   setEditFormData(newFormData);
  // };

  // const handleEditFormSubmit = (event) => {
  //   event.preventDefault();

  //   const editedItem = {
  //     doctor: editFormData.doctor,
  //     clinic: editFormData.clinic,
  //     city: editFormData.city,
  //     region: editFormData.region,
  //     phone: editFormData.phone,
  //     cardNumber: editFormData.cardNumber,
  //   };

  //   dispatch(updatePromocodeItemAction(editItemId, editedItem, userData));
  //   setEditItemId(null);
  // };
  // const handleActivateClick = (event, item, itemId) => {
  //   event.preventDefault();

  //   const editedItem = {
  //     activated: true,
  //   };

  //   dispatch(updatePromocodeItemAction(itemId, editedItem, userData));
  //   setEditItemId(null);
  // };

  // const handleEditClick = (event, item, itemId) => {
  //   event.preventDefault();
  //   setEditItemId(itemId);

  //   const formValues = {
  //     // manager: item.manager.managerName,
  //     // promocode: item.promocode,
  //     doctor: item.doctor,
  //     clinic: item.clinic,
  //     city: item.city,
  //     region: item.region,
  //     phone: item.phone,
  //     cardNumber: item.cardNumber,
  //   };

  //   setEditFormData(formValues);
  // };

  // const handleCancelClick = () => {
  //   setEditItemId(null);
  // };

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  }

  return (
    <>
      <div className={classes.filterRow}>
        <FormControl className={classes.formControl}>
          <InputLabel id="manager label">Менеджер</InputLabel>
          <Select
            value={managerId}
            onChange={handleChangeManager}
          >
            {managerList.map(item => {
              return <MenuItem value={item.id}>{item.managerName}</MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper} className={classes.container} elevation={0}>
        <Table className={classes.table} size="small" aria-label="a dense table" >
          <TableHead className={classes.stickyHeader}>
            <TableRow>
              <TableCell className={classes.tableCell}>Менеджер</TableCell>
              <TableCell className={classes.tableCell}>Промокод</TableCell>
              <TableCell className={classes.tableCell}>ФИО доктора</TableCell>
              <TableCell className={classes.tableCell}>Клиника</TableCell>
              <TableCell className={classes.tableCell}>Город</TableCell>
              <TableCell className={classes.tableCell}>Регион</TableCell>
              <TableCell className={classes.tableCell}>Номер телефона</TableCell>
              <TableCell className={classes.tableCell}>Номер карты</TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => {
              // console.log("🚀 ~ file: ViewTableDoctorsBonusList.js ~ line 108 ~ ViewTableDoctorsBonusList ~ item", item)
              return (
                <AdminPromocodeReadRow
                  item={item}
                  itemId={item.id}
                  status={status}
                // handleEditClick={handleEditClick}
                // handleActivateClick={handleActivateClick}
                // handleDeleteClick={handleDeleteClick}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default AdminEditTablePromocodeList