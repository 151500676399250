import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import ViewTableDoctorsBonusList from '../views/ViewTableDoctorsBonusList';
import { adminDataSelector, userDataSelector } from '../data/selectors/userDataSelector';
import { loadStartAction } from '../data/actions/loadStartActions';
import { NEW_PROMOCODE_STATUS, ACTIVE_PROMOCODE_STATUS } from '../data/constants/statuses';
import Typography from '@material-ui/core/Typography';
import { newPromocodeListSelector } from '../data/selectors/promocodeSelector';
import EditTablePromocodeList from '../views/EditTablePromocodeList';
import { loadAllManagersPromocodeListAction, loadManagerPromocodeListAction } from './../data/actions/promocodeActions';
import AdminEditTablePromocodeList from './../views/AdminEditTablePromocodeList';
import { allPromocodeListSelector } from './../data/selectors/promocodeSelector';
import { loadManagerListAction } from '../data/actions/managerListActions';
import { allManagerListSelector } from '../data/selectors/managerListSelector';
import { addUserLogDateApi } from '../api/logDataApi';

const AdminAllPromocodesContainer = () => {

    const dispatch = useDispatch()

    const [managerId, setManagerId] = useState(0)
    let locationLang = JSON.parse(localStorage.getItem('location'));
    const [location, setLocation] = useState(locationLang ? locationLang : 'ru-RU');

    const adminData = useSelector(adminDataSelector)
    const managerList = useSelector(allManagerListSelector)
    const allPromocodeList = useSelector(allPromocodeListSelector)
    const userData = useSelector(userDataSelector)

    const handleChangeManager = (event) => {
        setManagerId(event.target.value)
    }

    useEffect(() => {
        dispatch(loadManagerListAction(userData, location))
        localStorage.setItem('location', JSON.stringify(location));
        dispatch(loadAllManagersPromocodeListAction(userData, managerId, location))
        addUserLogDateApi(userData, '/admin/allPromocodes')
    }, [managerId, location])

    return (
        <AdminEditTablePromocodeList
            items={allPromocodeList}
            adminData={adminData}
            status={NEW_PROMOCODE_STATUS}
            managerId={managerId}
            managerList={managerList}
            location={location}
            setLocation={setLocation}
            handleChangeManager={handleChangeManager}
        />
    )
}

export default AdminAllPromocodesContainer
