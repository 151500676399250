import { addUserLogDateApi } from "../../api/logDataApi";
import { getMainManagerListApi, getManagerSaleListApi, updateManagerSaleFieldByIdApi } from "../../api/managerListApi"
import { MAIN_MANAGER_LIST_LOAD_SUCCESSFUL, MANAGER_LIST_LOAD_SUCCESSFUL, MANAGER_SALE_LIST_LOAD_SUCCESSFUL } from "../reducers/managerListReducer"
import { getAllManagerSaleListApi, getManagerListApi, getAllManagerListApi } from './../../api/managerListApi';
import { setSnackbarAction } from './snackbarActions';

export const loadManagerListAction = (userData, location) => {
    return async (dispatch) => {
        const resManagers = await getAllManagerListApi(userData, location)
        dispatch({
            type: MANAGER_LIST_LOAD_SUCCESSFUL,
            payload: resManagers,
        })
    }
}

export const loadMainManagerListAction = (userData, location) => {
    return async (dispatch) => {
        const resManagers = await getMainManagerListApi(userData, location)
        dispatch({
            type: MAIN_MANAGER_LIST_LOAD_SUCCESSFUL,
            payload: resManagers,
        })
    }
}

export const getManagerSaleListAction = (userData, year) => {
    return async (dispatch) => {
        const res = await getManagerSaleListApi(userData, year)
        dispatch({
            type: MANAGER_SALE_LIST_LOAD_SUCCESSFUL,
            payload: res,
        })
    }
}

export const getAllManagerSaleListAction = (userData, year) => {
    return async (dispatch) => {
        const res = await getAllManagerSaleListApi(userData, year)
        dispatch({
            type: MANAGER_SALE_LIST_LOAD_SUCCESSFUL,
            payload: res,
        })
    }
}

export const updateManagerSaleFieldAction = (fieldId, data, userData, year) => {
    return async (dispatch) => {
        const res = await updateManagerSaleFieldByIdApi(fieldId, data, userData)
        if (res !== 200) return dispatch(setSnackbarAction(true, "error", "Ошибка! Данные не сохранены."))
        if (res === 200) {
            dispatch(getAllManagerSaleListAction(userData, year))
            dispatch(setSnackbarAction(true, "success", "Данные сохранены."))
            return addUserLogDateApi(userData, `/admin/updateDashboardItem/${fieldId}/${JSON.stringify(data)}`)
        }
    }
}