import { createSelector } from 'reselect';
import date from 'date-and-time';

export const userSelector = (state) => state.authentication.user

export const userDataSelector = createSelector(
    [userSelector],
    (user) => {
        const token = user.jwt
        const managerId = user.user.manager_id.id
        const managerLocale = 'ru-RU'
        const managerLocation = user.user.manager_id.managerLocation
        const managerName = user.user.manager_id.managerName
        const managerBonusPercent = user.user.manager_id.bonusPercent
        const managerGroup = user.user.manager_id.groupName
        const managerRole = user.user.role.name
        let d = new Date();
        d.setMonth(d.getMonth());
        const monthData = Number(date.format(d, 'M')) === 1 ? 12 : Number(date.format(d, 'M') - 1);
        const yearData = monthData === 12 ? Number(date.format(d, 'YYYY')) - 1 : Number(date.format(d, 'YYYY'));
        const settingsData = { monthData, yearData }
        return { token, managerId, managerLocale, managerName, settingsData, managerBonusPercent, managerLocation, managerGroup, managerRole }
    }
)

export const adminDataSelector = createSelector(
    [userSelector],
    (user) => {
        const token = user.jwt
        const managerId = user.user.manager_id.id
        let d = new Date();
        d.setMonth(d.getMonth() - 1);
        const monthData = Number(date.format(d, 'M'));
        const yearData = Number(date.format(d, 'YYYY'));
        const settingsData = { monthData, yearData }
        const role = user.user.role.name
        const adminLocale = 'ru-RU'
        const username = user.user.username
        return { token, managerId, settingsData, role, adminLocale, username }
    }
)