import { combineReducers } from 'redux'
import { areaListReducer } from './areaListReducer'
import { cityListReducer } from './cityListReducer'
import { managerListReducer } from './managerListReducer'
import { orderListReducer } from './orderListReducer'
import { promocodeReducer } from './promocodeReducer';
import { authenticationReducer } from './authenticationReducer';
import settingsReducer from './settingsReducer'
import snackbarReducer from './snackbarReducer';
import chartDataReducer from './chartDataReducer'

export const CombinedReducer = combineReducers({
    orderList: orderListReducer,
    managerList: managerListReducer,
    areaList: areaListReducer,
    cityList: cityListReducer,
    promocode: promocodeReducer,
    // settings: settingsReducer,
    authentication: authenticationReducer,
    snackbar: snackbarReducer,
    chartData: chartDataReducer
})

export default {
    CombinedReducer,
}
