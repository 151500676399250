import React, { useState, useEffect } from 'react';
import { MenuItem, Select, FormControl, InputLabel, Button, Divider } from '@material-ui/core';
import axios from 'axios';
import { BACKEND_DOMAIN } from './../data/constants/statuses';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { useSelector } from 'react-redux';
import { getUpdateDataApi } from './../api/updateDataApi';
import { addPromocodeApi, getAllManagersPromocodeListApi, manualUpdatePromocodeApi } from './../api/promocodesApi';
import { addJuridicalOrderApi, addWildberriesOrderApi, addYandexOrderApi } from '../api/orderListApi';
import { loadStartAction } from '../data/actions/loadStartActions';
import { addUserLogDateApi } from '../api/logDataApi';

const UpdateDataContainer = () => {

    const userData = useSelector(userDataSelector)

    const [products, setProducts] = useState([]);
    // console.log("🚀 ~ UpdateDataContainer ~ products:", products)

    const handlePostInsalesOrders = async () => {
        const typeValue = 'postInsalesOrders';
        getUpdateDataApi(userData, typeValue);
    };

    const handleCollectOzonOrders = async () => {
        const typeValue = 'collectOzonOrders';
        getUpdateDataApi(userData, typeValue);
    };

    //   const handleCalcManagersProductQty = async () => {
    //     const typeValue = 'calcManagersProductQty';
    //     getUpdateDataApi(userData, typeValue);
    //   };

    const handleCalcPromocodesBonuses = async () => {
        const typeValue = 'calcPromocodesBonuses';
        getUpdateDataApi(userData, typeValue);
    };

    const handleCalcManagersSales = async () => {
        const typeValue = 'calcManagersSales';
        getUpdateDataApi(userData, typeValue);
    };

    const handleCalcManagersEverydaysSales = async () => {
        const typeValue = 'calcManagersEverydaysSales';
        getUpdateDataApi(userData, typeValue);
    };

    const handlePostInsalesPromocodes = async () => {
        const typeValue = 'postInsalesPromocodes';
        getUpdateDataApi(userData, typeValue);
    };

    const handleAddJuridicalOrderApi = async () => {
        const typeValue = 'AddJuridicalOrderApi';
        addJuridicalOrderApi(userData, typeValue);
    };
    const handleAddWildberriesOrderApi = async () => {
        const typeValue = 'AddWildberriesOrderApi';
        addWildberriesOrderApi(userData, typeValue);
    };
    const handleCollectYandexOrders = async () => {
        const typeValue = 'collectYandexOrders';
        getUpdateDataApi(userData, typeValue);
    };

    const handlefetchMarketsData = async () => {
        const typeValue = 'fetchMarketsData';
        const res = await getUpdateDataApi(userData, typeValue);
        console.log("🚀 ~ handlefetchMarketsData ~ res:", res)
        if (res.status === 200) {
            setProducts(res.data);
        }
    };

    const handleLoadStart = async () => {
        const location = "ru-RU"
        const promocodes = await getAllManagersPromocodeListApi(userData, 0, location)
        manualUpdatePromocodeApi(userData, promocodes, location)
    };

    const handleCalcRegmanSales = async () => {
        const typeValue = 'calcRegmanSales';
        getUpdateDataApi(userData, typeValue);
    };

    const handlePostKZOrders = async () => {
        const typeValue = 'postKZOrders';
        getUpdateDataApi(userData, typeValue);
    };

    const handlePostKZPromocodes = async () => {
        const typeValue = 'postKZPromocodes';
        getUpdateDataApi(userData, typeValue);
    };

    useEffect(() => {
        // console.log("🚀 ~ UpdateDataContainer ~ products:", products)
    }, [products]);

    useEffect(() => {
        addUserLogDateApi(userData, '/admin/updateData')
    }, []);

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handlePostInsalesOrders}>
                PostInsalesOrders
            </Button>
            <Button variant="contained" color="primary" onClick={handleCollectOzonOrders}>
                CollectOzonOrders
            </Button>
            {/* <Button variant="contained" color="primary" onClick={handleCalcManagersProductQty}>
                CalcManagersProductQty
            </Button> */}
            <Button variant="contained" color="primary" onClick={handleCalcPromocodesBonuses}>
                CalcPromocodesBonuses
            </Button>
            <Button variant="contained" color="primary" onClick={handleCalcManagersSales}>
                CalcManagersSales
            </Button>
            <Button variant="contained" color="primary" onClick={handleCalcManagersEverydaysSales}>
                CalcManagersEverydaysSales
            </Button>
            <Button variant="contained" color="primary" onClick={handleAddJuridicalOrderApi}>
                AddJuridicalOrderApi
            </Button>
            <Button variant="contained" color="primary" onClick={handlePostInsalesPromocodes}>
                PostInsalesPromocodes
            </Button>
            <Button variant="contained" color="primary" onClick={handleAddWildberriesOrderApi}>
                AddWildberriesOrderApi
            </Button>
            <Button variant="contained" color="primary" onClick={handleCollectYandexOrders}>
                CollectYandexOrders
            </Button>
            <Button variant="contained" color="primary" onClick={handlefetchMarketsData}>
                fetchMarketsData
            </Button>
            <Button variant="contained" color="primary" onClick={handleLoadStart}>
                loadStart
            </Button>
            <Button variant="contained" color="primary" onClick={handleCalcRegmanSales}>
            CalcRegmanSales
            </Button>
            <br />
            3724
            <br />
            <Button variant="contained" color="primary" onClick={handlePostKZOrders}>
            postKZOrders
            </Button>
            <Button variant="contained" color="primary" onClick={handlePostKZPromocodes}>
            postKZPromocodes
            </Button>
        </div>
    );
};

export default UpdateDataContainer;
