import axios from 'axios';
import { BACKEND_DOMAIN } from "../data/constants/statuses"

export const getUserData = async () => {

    const res = await axios
        .get(`https://api.ipify.org?format=json`).then(response => {
            return response.data
        });
    return res;
}

export const addUserLogDateApi = async (userData, page) => {

    const { token, managerId } = userData

    const userAddressData = await getUserData()
    const userCountry = userAddressData.ip || 'unknown'
    const data = { managerId: managerId, page, userCountry: userCountry }

    axios
        .post(`${BACKEND_DOMAIN}/user-logs`, data,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
}
