import React from "react";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Input from '@material-ui/core/Input';
import { ACTIVE_PROMOCODE_STATUS } from "../data/constants/statuses";
import { Button, makeStyles } from "@material-ui/core";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    padding: '6px 8px 6px 8px'
  },
  button: {
    margin: theme.spacing(0),
    padding: 2,
    minWidth: 20
  }
}));

const PromocodeEditableRow = ({
  editFormData,
  item,
  status,
  handleEditFormChange,
  handleEditFormSubmit,
  handleCancelClick,
}) => {
  const classes = useStyles();

  return (
    <TableRow>
      {/* <TableCell>
        <Input
          type="text"
          required="required"
          placeholder="Enter a name..."
          name="manager"
          value={editFormData.manager}
          onChange={handleEditFormChange}
        />
      </TableCell> */}
      <TableCell className={classes.tableCell}>
        {item.promocode}
      </TableCell>
      <TableCell component="th" scope="row" className={classes.tableCell}>
        <Input
          type="text"
          required="required"
          placeholder="Enter doctor name..."
          name="doctor"
          value={editFormData.doctor}
          onChange={handleEditFormChange}
        />
      </TableCell>
      <TableCell component="th" scope="row" className={classes.tableCell}>
        <Input
          type="text"
          required="required"
          placeholder="Enter clinic..."
          name="clinic"
          value={editFormData.clinic}
          onChange={handleEditFormChange}
        />
      </TableCell>
      <TableCell component="th" scope="row" className={classes.tableCell}>
        <Input
          type="text"
          required="required"
          placeholder="Enter city..."
          name="city"
          value={editFormData.city}
          onChange={handleEditFormChange}
        />
      </TableCell>
      <TableCell component="th" scope="row" className={classes.tableCell}>
        <Input
          type="text"
          required="required"
          placeholder="Enter region..."
          name="region"
          value={editFormData.region}
          onChange={handleEditFormChange}
        />
      </TableCell>
      <TableCell component="th" scope="row" className={classes.tableCell}>
        <Input
          type="text"
          required="required"
          placeholder="Enter a phone number..."
          name="phone"
          value={editFormData.phone}
          onChange={handleEditFormChange}
        />
      </TableCell>
      <TableCell component="th" scope="row" className={classes.tableCell}>
        <Input
          type="text"
          required="required"
          placeholder="Enter card number..."
          name="cardNumber"
          value={editFormData.cardNumber}
          onChange={handleEditFormChange}
        />
      </TableCell>
      <TableCell component="th" scope="row" className={classes.tableCell}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={classes.button}
          onClick={handleEditFormSubmit}>
          <SaveIcon />
        </Button>
      </TableCell>
      <TableCell component="th" scope="row" className={classes.tableCell}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={classes.button}
          onClick={handleCancelClick}>
          <CancelOutlinedIcon />
        </Button>
      </TableCell>
    </TableRow>

  );
};

export default PromocodeEditableRow;