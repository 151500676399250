import { AppBar, makeStyles, Toolbar, Typography, Button, Link, Divider, Grid, Hidden } from "@material-ui/core"
import DashboardManagerSalesContainer from '../containers/DashboardManagerSalesContainer';
import { useSelector } from 'react-redux';
import { userDataSelector } from "../data/selectors/userDataSelector";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    managerName: {
        fontSize: 14,
        lineHeight: 1.2,
        [theme.breakpoints.up('md')]: {
            fontSize: 14
        },
        [theme.breakpoints.down('sm')]: {
            lineHeight: 1.4,
        },
    },
    title: {
        flexGrow: 1,
    },
    logoText: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1.2,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            fontSize: 16
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            lineHeight: 1.4
        },
    },
    link: {
        color: '#ffffff',
        [theme.breakpoints.up('md')]: {
            fontSize: 14,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
            lineHeight: 1.4,
        },
        marginRight: theme.spacing(2),
        // lineHeight: 1.6
    },
    menu: {
        position: 'relative',
        top: 5,
        [theme.breakpoints.down('sm')]: {
            top: -2,
            left: 10
        },
    }
}));

const HeaderLayout = () => {
    const classes = useStyles();

    const userData = useSelector(userDataSelector)

    const preventDefault = (event) => event.preventDefault();

    return (
        <AppBar position="fixed">
            <Toolbar>
                <Grid container spacing={1}>
                    <Grid item xs={1} md={1}>
                        <Typography className={classes.logoText}>
                            Perolite<br/>Crm
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={8} className={classes.menu}>
                        <Link href="/" className={classes.link}>
                            Дашборд
                        </Link>
                        <Link href="/newPromocodes" className={classes.link}>
                            Новые&nbsp;промокоды
                        </Link>
                        <Link href="/bonusForm" className={classes.link}>
                            Бонусы&nbsp;докторов
                        </Link>
                        <Hidden mdUp><br/></Hidden>
                        <Link href="/bonusReport" className={classes.link}>
                            Архив&nbsp;бонусов
                        </Link>
                        <Link href="/orderListReport" className={classes.link}>
                            Заказы
                        </Link>
                       {userData.managerId !== 56 && <Link href="/chart" className={classes.link}>
                            График
                        </Link>}
                        <Link href="/"
                            className={classes.link}
                            onClick={() => localStorage.removeItem('user')}
                        >
                            Выйти
                        </Link>
                    </Grid>
                    <Grid item xs={5} md={3}>
                        <Typography variant="h6" align='right' className={classes.managerName}>
                            Менеджер:<br/>{userData.managerName}
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default HeaderLayout