import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DashboardTableRow from './DashboardTableRow';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
    position: 'relative',
    top: -11
  },
  container: {
    maxHeight: 1000,
    padding: '0 0 10px',
    overflow: 'visible',
    marginTop: 122
  },
  tableRow: {
    backgroundColor: '#e8ebf7',
  },
  stickyHeader: {
    backgroundColor: '#e8ebf7',
    position: "sticky",
    top: 112,
    zIndex: 100,
    boxShadow: '1px 1px 1px #ccc'
  },
  formControl: {
    width: 140,
    marginLeft: 10
  },
  managerName: {
    marginTop: 20,
    marginLeft: 15
  },
  filterRow: {
    backgroundColor: '#e8ebf7',
    width: '100%',
    display: 'inline-block',
    position: 'fixed',
    top: 64,
    zIndex: 101,
    borderBottom: '1px solid #ccc'
  },
});

const ViewTableManagersTotalSale = (props) => {

  const { items, year, userData, handleChangeYear } = props
  const classes = useStyles();

  return (
    <>
      <div className={classes.filterRow}>
        <FormControl className={classes.formControl}>
          <InputLabel id="year label">Год</InputLabel>
          <Select
            value={year}
            onChange={handleChangeYear}
          >
            <MenuItem value={2024}>2024</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2021}>2021</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper} className={classes.container} elevation={0}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead className={classes.stickyHeader}>
            <TableRow className={classes.tableRow}>
              <TableCell></TableCell>
              <TableCell>Январь</TableCell>
              <TableCell>Февраль</TableCell>
              <TableCell>Март</TableCell>
              <TableCell>Апрель</TableCell>
              <TableCell>Май</TableCell>
              <TableCell>Июнь</TableCell>
              <TableCell>Июль</TableCell>
              <TableCell>Август</TableCell>
              <TableCell>Сентябрь</TableCell>
              <TableCell>Октябрь</TableCell>
              <TableCell>Ноябрь</TableCell>
              <TableCell>Декабрь</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <DashboardTableRow items={items} title={'Интернет-продажи с промокодами'} fieldName={'saleSum'} rowColor='blue' />
            <DashboardTableRow items={items} title={'Интернет-продажи без промокодов'} fieldName={'otherSale'} rowColor='blue' />
            <DashboardTableRow items={items} title={'Интернет-продажи косметологи'} fieldName={'cosmetologSale'} rowColor='blue' />
            <DashboardTableRow items={items} title={'Маркетплейсы'} fieldName={'marketsSale'} rowColor='blue' />
            <DashboardTableRow items={items} title={'Аптеки'} fieldName={'aptekSale'} rowColor='blue' />
            <DashboardTableRow items={items} title={'Клиники'} fieldName={'clinicSale'} rowColor='blue' />
            <DashboardTableRow items={items} title={'Дистрибьюторы'} fieldName={'distributorSale'} rowColor='blue' />
            <DashboardTableRow items={items} title={'Общая сумма продаж'} fieldName={'totalSaleSum'} rowColor='blue' />
            <DashboardTableRow items={items} title={'Бонус менеджера'} fieldName={'managerBonusSum'} rowColor='green' />
            <DashboardTableRow items={items} title={'Доп. бонус'} fieldName={'extraSum'} rowColor='green' />
            <DashboardTableRow items={items} title={'Миноклад'} fieldName={'minOklad'} rowColor='green' />
            <DashboardTableRow items={items} title={'Общий доход менеджера'} fieldName={'managerProfit'} rowColor='green' rowFont='bold' />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default ViewTableManagersTotalSale