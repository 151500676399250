import React from "react";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ACTIVE_PROMOCODE_STATUS } from "../data/constants/statuses";
import { NEW_PROMOCODE_STATUS } from '../data/constants/statuses';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    padding: '6px 8px 6px 8px'
  },
  button: {
    margin: theme.spacing(0),
    padding: 2,
    minWidth: 20
  },
  disabled: {
    // backgroundColor: '#f1f1f1',
    color: '#777'
  }
}));

const PromocodeBonusReadRow = ({ item, itemId, handleEditClick, handleActivateClick, isEditable }) => {

  const classes = useStyles();

  const disabledRow = item.activated === true ? classes.disabled : ''

  return (
    <TableRow key={itemId} className={disabledRow}>
      {/* <TableCell component="th" scope="row">
        {item.manager.managerName}
      </TableCell> */}
      <TableCell component="th" scope="row" className={[classes.tableCell, disabledRow]}>
        {item.promocode}
      </TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>{item.doctor}</TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>{item.clinic}</TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>{item.city}</TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>{item.region}</TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>{item.phone}</TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>{item.cardNumber}</TableCell>
      <TableCell className={[classes.tableCell, disabledRow]}>{item.bonusMonth}</TableCell>
      <TableCell component="th" scope="row" className={[classes.tableCell, disabledRow]}>
        {item.bonusSum}
      </TableCell>
      <TableCell component="th" scope="row" className={[classes.tableCell, disabledRow]}>
        {item.bonusExtra}
      </TableCell>
      <TableCell component="th" scope="row" className={[classes.tableCell, disabledRow]}>
        {Number(item.bonusSum) + Number(item.bonusExtra)}
      </TableCell>
      {isEditable && (
        <>
          <TableCell component="th" scope="row" className={classes.tableCell}>
            {item.activated !== true && <Button variant="outlined" size="small" color="primary" className={classes.button}
              onClick={(event) => handleEditClick(event, item, itemId)}
            >
              <EditIcon />
            </Button >
            }
          </TableCell>
          <TableCell component="th" scope="row" className={classes.tableCell}>
            {item.activated !== true && <Button variant="outlined" size="small" color="primary" className={classes.button}
              onClick={(event) => handleActivateClick(event, item, itemId)}
            >
              <SendIcon />
            </Button >
            }
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default PromocodeBonusReadRow;