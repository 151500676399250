 const mytext =

`
1CClientBankExchange
ВерсияФормата=1.03
Кодировка=Windows
Отправитель=СБИС
ДатаСоздания=06.12.2024
ВремяСоздания=18:21:23
ДатаНачала=06.12.2024
ДатаКонца=06.12.2024
РасчСчет=40702810538000147270
СекцияДокумент=Платежное поручение
Номер=2568
Дата=02.12.2024
Сумма=9108.00
ПлательщикСчет=40702810162000024866
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "МЕДИЦИНА КРАСОТЫ"
ПлательщикИНН=1655089121
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "МЕДИЦИНА КРАСОТЫ"
ПлательщикРасчСчет=40702810162000024866
ПлательщикБанк1=ОТДЕЛЕНИЕ "БАНК ТАТАРСТАН" N8610 ПАО СБЕРБАНК
ПлательщикБанк2=КАЗАНЬ
ПлательщикБИК=049205603
ПлательщикКорсчет=30101810600000000603
ПолучательСчет=40702810538000147270
ДатаПоступило=02.12.2024
Получатель=ООО "ПЕРОЛАЙТ"
ПолучательИНН=7716859156
Получатель1=ООО "ПЕРОЛАЙТ"
ПолучательРасчСчет=40702810538000147270
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
НазначениеПлатежа=Оплата по договору 2414/1 от 23.05.2023.  и по акту сверки на 30.11.2024 за косметологический товар Сумма 9108-00 В т.ч. НДС  (20%) 1518-00
НазначениеПлатежа1=Оплата по договору 2414/1 от 23.05.2023.  и по акту сверки на 30.11.2024 за косметологический товар Сумма 9108-00 В т.ч. НДС  (20%) 1518-00
ПлательщикКПП=165501001
ПолучательКПП=772101001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=2038
Дата=03.12.2024
Сумма=23063.00
ПлательщикСчет=40702810001690000469
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЗДРАВИЯ"
ПлательщикИНН=7104070728
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЗДРАВИЯ"
ПлательщикРасчСчет=40702810001690000469
ПлательщикБанк1=АО "АЛЬФА-БАНК"
ПлательщикБанк2=Москва
ПлательщикБИК=044525593
ПлательщикКорсчет=30101810200000000593
ПолучательСчет=40702810538000147270
ДатаПоступило=03.12.2024
Получатель=ООО "ПЕРОЛАЙТ"
ПолучательИНН=7716859156
Получатель1=ООО "ПЕРОЛАЙТ"
ПолучательРасчСчет=40702810538000147270
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=ОПЛАТА ЗА ТОВАР ПО ДОГОВОРУ С ПОСТАВЩИКОМ № 2438 ОТ 07.02.2024Г. СОГЛАСНО СЧЕТА № 10023 ОТ 02.12.2024Г. СУММА 23063-00 В Т.Ч. НДС (20%) 3843-83
НазначениеПлатежа1=ОПЛАТА ЗА ТОВАР ПО ДОГОВОРУ С ПОСТАВЩИКОМ № 2438 ОТ 07.02.2024Г. СОГЛАСНО СЧЕТА № 10023 ОТ 02.12.2024Г. СУММА 23063-00 В Т.Ч. НДС (20%) 3843-83
ПлательщикКПП=710301001
ПолучательКПП=772101001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=1465
Дата=03.12.2024
Сумма=29067.00
ПлательщикСчет=40802810402920006687
Плательщик=ИП Захарова Светлана Петровна
ПлательщикИНН=711801172591
Плательщик1=ИП Захарова Светлана Петровна
ПлательщикРасчСчет=40802810402920006687
ПлательщикБанк1=АО "АЛЬФА-БАНК"
ПлательщикБанк2=Москва
ПлательщикБИК=044525593
ПлательщикКорсчет=30101810200000000593
ПолучательСчет=40702810538000147270
ДатаПоступило=03.12.2024
Получатель=ООО "ПЕРОЛАЙТ"
ПолучательИНН=7716859156
Получатель1=ООО "ПЕРОЛАЙТ"
ПолучательРасчСчет=40702810538000147270
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=ОПЛАТА ЗА ТОВАР ПО ДОГОВОРУ С ПОСТАВЩИКОМ № 2434 ОТ 07.07.2023Г. СОГЛАСНО СЧЕТА № 10024 ОТ 02.12.2024Г. СУММА 29067-00 В Т.Ч. НДС (20%) 4844-50
НазначениеПлатежа1=ОПЛАТА ЗА ТОВАР ПО ДОГОВОРУ С ПОСТАВЩИКОМ № 2434 ОТ 07.07.2023Г. СОГЛАСНО СЧЕТА № 10024 ОТ 02.12.2024Г. СУММА 29067-00 В Т.Ч. НДС (20%) 4844-50
ПолучательКПП=772101001
Очередность=5
КонецДокумента
КонецФайла
`

export default mytext