import axios from 'axios';
import date from 'date-and-time';
import { BACKEND_DOMAIN } from './../data/constants/statuses';

// Request API.
export const loginApi = async (username, password) => {
    const res = axios
        .post(`${BACKEND_DOMAIN}/auth/local`, {
            identifier: username,
            password: password,
        })
        .then(response => {
            // Handle success.
            // console.log('Well done!');
            // console.log('User profile', response.data.user);
            // console.log('User token', response.data.jwt);
            const user = response.data
            localStorage.setItem('user', JSON.stringify(user));
            return response
        })
    try {
        return res;
    } catch (error) {
        // Handle error.
        console.log('An error occurred:', error.response);
    }
}