import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import EditTableDoctorsBonusList from '../views/EditTableDoctorsBonusList';
import { orderListAllSelector } from '../data/selectors/orderListItemsSelector';
import { loadStartAction } from '../data/actions/loadStartActions';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { ACTIVE_PROMOCODE_STATUS } from '../data/constants/statuses';
import { newPromocodeBonusAllSumSelector, newPromocodeBonusListSelector } from '../data/selectors/promocodeSelector';
import { Link } from 'react-router-dom';
import { loadManagerPromocodeBonusListAction } from '../data/actions/promocodeActions';
import { settingsDataAction } from './../data/actions/settingsActions';
import { managerSalesFinLimitSelector } from '../data/selectors/managerListSelector';
import { getManagerSaleListAction } from '../data/actions/managerListActions';
import { addUserLogDateApi } from '../api/logDataApi';

const DoctorsBonusListContainer = () => {

    const dispatch = useDispatch()

    const newPromocodeBonusList = useSelector(newPromocodeBonusListSelector)
    const userData = useSelector(userDataSelector)
    const newPromocodeBonusAllSum = useSelector(newPromocodeBonusAllSumSelector)

    const yearData = userData.settingsData.yearData

    useEffect(() => {
        dispatch(getManagerSaleListAction(userData, yearData))
        dispatch(loadManagerPromocodeBonusListAction(userData))
        addUserLogDateApi(userData, '/bonusForm')
    }, [])

    return (
        <EditTableDoctorsBonusList items={newPromocodeBonusList} userData={userData} newPromocodeBonusAllSum={newPromocodeBonusAllSum} />
    )
}

export default DoctorsBonusListContainer
