import { AppBar, makeStyles, Toolbar, Typography, Button, Link, Divider } from "@material-ui/core"
import DashboardManagerSalesContainer from './../containers/DashboardManagerSalesContainer';
import { useSelector } from 'react-redux';
import { userDataSelector } from "../data/selectors/userDataSelector";
import AdminSummaryDataContainer from './../containers/AdminSummaryDataContainer';
import HeaderLayout from './../layouts/HeaderLayout';
import AdminHeaderLayout from './../layouts/AdminHeaderLayout';
import SnackbarComponent from './../components/SnackbarComponent';

const DashboardAdminPage = () => {

    return (
        <>
            <SnackbarComponent />
            <AdminHeaderLayout />
            <AdminSummaryDataContainer />
        </>
    )
}

export default DashboardAdminPage