export const AREA_LIST_LOAD_SUCCESSFUL = 'AREA_LIST_LOAD_SUCCESSFUL'

const initialState = {
    items: {},
}

export const areaListReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case AREA_LIST_LOAD_SUCCESSFUL:
            return { ...state, items: payload }
        default:
            return state
    }
}

export default { areaListReducer }
