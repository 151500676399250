import AdminDashboardManagerSalesContainer from './../containers/AdminDashboardManagerSalesContainer';
import AdminHeaderLayout from './../layouts/AdminHeaderLayout';
import SnackbarComponent from './../components/SnackbarComponent';

const DashboardAdminPage = () => {

    return (
        <>
            <SnackbarComponent />
            <AdminHeaderLayout />
            <AdminDashboardManagerSalesContainer />
        </>
    )
}

export default DashboardAdminPage