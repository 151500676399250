import { getChartDataApi } from "../../api/chartDataApi"
import { CHART_DATA_LOAD_SUCCESSFUL } from "../reducers/chartDataReducer"

export const getChartDataAction = (userData) => {
    return async (dispatch) => {
        const res = await getChartDataApi(userData)
        dispatch({
            type: CHART_DATA_LOAD_SUCCESSFUL,
            payload: res,
        })
    }
}