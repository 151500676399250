import './App.css';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './store';
import history from './history';
import ManagersSalesContainer from './containers/ManagersSalesContainer';
import LoginFormContainer from './containers/LoginFormContainer';
import { PrivateRoute } from './components/PrivateRoute';
import DashboardPage from './pages/DashboardPage';
import NewPromocodesPage from './pages/NewPromocodesPage';
import DoctorsBonusPage from './pages/DoctorsBonusPage';
import DoctorsBonusReportPage from './pages/DoctorsBonusReportPage';
import UpdateDataContainer from './containers/UpdateDataContainer';
import DashboardAdminPage from './pages/DashboardAdminPage';
import OrderListReportPage from './pages/OrderListReportPage';
import AdminOrderListReportPage from './pages/AdminOrderListReportPage';
import AdminDoctorsBonusReportPage from './pages/AdminDoctorsBonusReportPage';
import AdminAllPromocodesPage from './pages/AdminAllPromocodesPage';
import AdminSummaryDataPage from './pages/AdminSummaryDataPage';
import SummaryDataPage from './pages/SummaryDataPage';

function App() {

  return (
    <Router history={history}>
      <Switch>
        <Provider store={store}>
          <PrivateRoute exact path='/' component={DashboardPage} />
          <Route path='/login' component={LoginFormContainer} />
          <Route path='/newPromocodes' component={NewPromocodesPage} />
          <Route path='/bonusForm' component={DoctorsBonusPage} />
          <Route path='/bonusReport' component={DoctorsBonusReportPage} />
          <Route path='/orderListReport' component={OrderListReportPage} />
          <Route path='/chart' component={SummaryDataPage} />
          <Route path='/update-data' component={UpdateDataContainer} />
          <Route path='/admin/dashboard' component={DashboardAdminPage} />
          <Route path='/admin/allPromocodes' component={AdminAllPromocodesPage} />
          <Route path='/admin/doctorsBonusReport' component={AdminDoctorsBonusReportPage} />
          <Route path='/admin/orderListReport' component={AdminOrderListReportPage} />
          <Route path='/admin/summaryData' component={AdminSummaryDataPage} />
          {/* <Redirect from="*" to="/" /> */}
        </Provider>
      </Switch>
    </Router>
  );
}

export default App;
