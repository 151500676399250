import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { adminDataSelector, userDataSelector } from './../data/selectors/userDataSelector';
import { getChartDataAction } from './../data/actions/chartDataActions';
import { chartDataByMonthSelector, chartDataSelector } from './../data/selectors/chartDataSelector';
import AdminViewManagerListChart from '../views/AdminViewManagerListChart';
import { addUserLogDateApi } from '../api/logDataApi';

const AdminSummaryDataContainer = () => {

    const dispatch = useDispatch()
    const adminData = useSelector(adminDataSelector)
    const userData = useSelector(userDataSelector)

    const yearData = 2024  //adminData.settingsData.yearData
    const [period, setPeriod] = useState(6)
    const [year, setYear] = useState(yearData)
    const chartDayData = useSelector(chartDataSelector(period))
    const chartMonthData = useSelector(chartDataByMonthSelector(chartDayData, year))

    const chartData = period === 6 ? chartMonthData : chartDayData
    const isAdmin = adminData.role === 'admin' ? true : false
    const handleChangePeriod = (event) => {
        setPeriod(event.target.value)
    }
    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    useEffect(() => {
        dispatch(getChartDataAction(adminData))
        addUserLogDateApi(userData, '/admin/summaryData')
    }, [])

    return (
        <AdminViewManagerListChart
            chartData={chartData}
            period={period}
            year={year}
            isAdmin={isAdmin}
            handleChangePeriod={handleChangePeriod}
            handleChangeYear={handleChangeYear}
        />
    )
}

export default AdminSummaryDataContainer
