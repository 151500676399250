import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { currentMonthSelector, orderListAllSelector } from './../data/selectors/orderListItemsSelector';
import ViewTableOrderList from './../views/ViewTableOrderList';
import { loadOrderListByMonthAction, loadMonthOrderListAction } from './../data/actions/orderListActions';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { addUserLogDateApi } from '../api/logDataApi';
import { loadManagerPromocodeListAction } from '../data/actions/promocodeActions';

const OrderListPerMonthContainer = () => {


    const dispatch = useDispatch()

    const userData = useSelector(userDataSelector)
    const currentMonth = userData.settingsData.monthData
    const currentYear = userData.settingsData.yearData
    const [month, setMonth] = useState(currentMonth)
    const [year, setYear] = useState(currentYear)
    const orders = useSelector(orderListAllSelector)
    // const currentMonth = useSelector(currentMonthSelector)
    // console.log("🚀 ~ file: OrderListPerMonthContainer.js ~ line 13 ~ OrderListPerMonthContainer ~ currentMonth", currentMonth)

    // const { month, setMonth } = useState(7)
    // console.log("🚀 ~ file: OrderListPerMonthContainer.js ~ line 17 ~ OrderListPerMonthContainer ~ month", month)

    // const year = 2021
    // const month = 9

    const handleChangeMonth = (event) => {
        setMonth(event.target.value)
    }
    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    useEffect(() => {
        dispatch(loadMonthOrderListAction(userData, year, month))
        addUserLogDateApi(userData, '/orderListReport')
    }, [month, year])


    useEffect(() => {
        dispatch(loadManagerPromocodeListAction(userData))
    }, [])

    return (
        // <div>111</div>
        <ViewTableOrderList
            items={orders}
            month={month}
            year={year}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
        />
    )
}

export default OrderListPerMonthContainer
