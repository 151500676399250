export const CITY_LIST_LOAD_SUCCESSFUL = 'CITY_LIST_LOAD_SUCCESSFUL'

const initialState = {
    items: {},
}

export const cityListReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CITY_LIST_LOAD_SUCCESSFUL:
            return { ...state, items: payload }
        default:
            return state
    }
}

export default { cityListReducer }
