import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userDataSelector } from './../data/selectors/userDataSelector';
import { getChartDataAction } from './../data/actions/chartDataActions';
import { chartDataSelector, chartDataByMonthSelector } from './../data/selectors/chartDataSelector';
import AdminViewManagerListChart from '../views/AdminViewManagerListChart';


const SummaryDataContainer = () => {

    const dispatch = useDispatch()
    const userData = useSelector(userDataSelector)
    const yearData = userData.settingsData.yearData
    const [period, setPeriod] = useState(6)
    const [year, setYear] = useState(yearData)
    const chartDayData = useSelector(chartDataSelector(period))
    const chartMonthData = useSelector(chartDataByMonthSelector(chartDayData, year))

    const chartData = period === 6 ? chartMonthData : chartDayData

    const handleChangePeriod = (event) => {
        setPeriod(event.target.value)
    }
    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    useEffect(() => {
        dispatch(getChartDataAction(userData))
    }, [])

    return (
        <AdminViewManagerListChart
            chartData={chartData}
            period={period}
            year={year}
            handleChangePeriod={handleChangePeriod}
            handleChangeYear={handleChangeYear}
        />
    )
}

export default SummaryDataContainer
