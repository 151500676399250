import { createSelector } from 'reselect';
import date from 'date-and-time';

export const chartItemsSelector = (state) => state.chartData.items

export const chartDataSelector = (period) => createSelector(
    [chartItemsSelector],
    (items) => {
        const trueItems = items.filter(item => item.totalSum > 0);
        const managerList = Array.from(trueItems, ({ mainCity }) => mainCity);
        const managerUniqList = Array.from(new Set(managerList))
        const saleDateList = Array.from(trueItems, ({ date }) => new Date(date).toString().substring(0, 15)).sort()
        const colorScheme = [
            "#25CCF7", "#FD7272", "#54a0ff", "#00d2d3",
            "#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e",
            "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50",
            "#f1c40f", "#e67e22", "#e74c3c", "#ecf0f1", "#95a5a6",
            "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d",
            "#55efc4", "#81ecec", "#74b9ff", "#a29bfe", "#dfe6e9",
            "#00b894", "#00cec9", "#0984e3", "#6c5ce7", "#ffeaa7",
            "#fab1a0", "#ff7675", "#fd79a8", "#fdcb6e", "#e17055",
            "#d63031", "#feca57", "#5f27cd", "#54a0ff", "#01a3a4"
        ]
        const subtractMonths = (numOfMonths, date = new Date()) => {
            date.setMonth(date.getMonth() - numOfMonths);

            return date;
        }
        const subtractDays = (numOfDays, date = new Date()) => {
            date.setDate(date.getDate() - numOfDays);

            return date;
        }
        const currentDate = new Date()
        let startDate = ''
        let endDate = date.format(currentDate, 'YYYY-MM-DD')
        const prevTwoWeek = subtractDays(14)
        const startTwoWeek = date.format(prevTwoWeek, 'YYYY-MM-DD')
        const prevMonth = subtractMonths(1)
        const startMonth = date.format(prevMonth, 'YYYY-MM-01')
        const prevThreeMonth = subtractMonths(3)
        const startThreeMonth = date.format(prevThreeMonth, 'YYYY-MM-01')
        const prevSixMonth = subtractMonths(6)
        const startSixMonth = date.format(prevSixMonth, 'YYYY-MM-01')
        const startYear = date.format(currentDate, 'YYYY-01-01')
        switch (period) {
            case 1:
                startDate = startTwoWeek
                break;
            case 2:
                startDate = startMonth;
                break;
            case 3:
                startDate = startThreeMonth;
                break;
            case 4:
                startDate = startSixMonth;
                break;
            case 5:
                startDate = startYear;
                break;
        }
        const sale = trueItems
        let managersTotalSum = []
        managerUniqList.forEach((mainCity, index) => {
            const saleItems = sale.filter(element => element.mainCity == mainCity && (element.date >= startDate && element.date <= endDate))
            let citySales = []
            saleItems.forEach(item => {
                const dateItem = new Date(item.date)
                const saleItem = { x: dateItem, y: item.totalSum }
                citySales.push(saleItem)
            })
            const sortCitySales = citySales.sort((a, b) => (a.x - b.x))
            const totalSum = sortCitySales.reduce((sum, i) => sum + i.y, 0)
            const lineColor = colorScheme[index]
            const res = { label: mainCity, data: sortCitySales, borderColor: lineColor, borderWidth: 2, tension: 0.1, totalSum: totalSum }
            managersTotalSum.push(res)

        })

        const res = { managersTotalSum, managerUniqList }
        return res
    }
)

export const chartDataByMonthSelector = (period, year) => createSelector(
    [chartItemsSelector],
    (items) => {
        const trueItems = items.filter(item => item.totalSum > 0);
        const managerList = Array.from(trueItems, ({ mainCity }) => mainCity);
        const managerUniqList = Array.from(new Set(managerList))
        const colorScheme = [
            "#25CCF7", "#FD7272", "#54a0ff", "#00d2d3",
            "#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e",
            "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50",
            "#f1c40f", "#e67e22", "#e74c3c", "#ecf0f1", "#95a5a6",
            "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d",
            "#55efc4", "#81ecec", "#74b9ff", "#a29bfe", "#dfe6e9",
            "#00b894", "#00cec9", "#0984e3", "#6c5ce7", "#ffeaa7",
            "#fab1a0", "#ff7675", "#fd79a8", "#fdcb6e", "#e17055",
            "#d63031", "#feca57", "#5f27cd", "#54a0ff", "#01a3a4"
        ]
        const subtractMonths = (numOfMonths, date = new Date()) => {
            date.setMonth(date.getMonth() - numOfMonths);

            return date;
        }
        const subtractDays = (numOfDays, date = new Date()) => {
            date.setDate(date.getDate() - numOfDays);

            return date;
        }
        const currentDate = new Date()
        let startDate = ''
        let endDate = date.format(currentDate, 'YYYY-MM-DD')
        const prevTwoWeek = subtractDays(14)
        const startTwoWeek = date.format(prevTwoWeek, 'YYYY-MM-DD')
        const prevMonth = subtractMonths(1)
        const startMonth = date.format(prevMonth, 'YYYY-MM-01')
        const prevThreeMonth = subtractMonths(3)
        const startThreeMonth = date.format(prevThreeMonth, 'YYYY-MM-01')
        const prevSixMonth = subtractMonths(6)
        const startSixMonth = date.format(prevSixMonth, 'YYYY-MM-01')
        const startYear = date.format(currentDate, 'YYYY-01-01')
        switch (period) {
            case 1:
                startDate = startTwoWeek
                break;
            case 2:
                startDate = startMonth;
                break;
            case 3:
                startDate = startThreeMonth;
                break;
            case 4:
                startDate = startSixMonth;
                break;
            case 5:
                startDate = startYear;
                break;
        }
        const sale = trueItems
        const monthList = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
        let managersTotalSum = []
        managerUniqList.forEach((mainCity, index) => {
            let salesByMonthList = []
            monthList.forEach((month, index) => {
                const filterList = (item) => {
                    if (item.mainCity === mainCity && (item.date >= `${year}-${month}-01` && item.date <= `${year}-${month}-31`)) {
                        return item
                    }
                }
                const res = sale.filter(filterList)
                const saleItemsSum = res.reduce((sum, i) => sum + i.totalSum, 0)

                const saleItem = { x: month, y: saleItemsSum, city: mainCity }
                salesByMonthList.push(saleItem)
            })
            const sortCitySales = salesByMonthList.sort((a, b) => (a.x - b.x))
            const totalSum = sortCitySales.reduce((sum, i) => sum + i.y, 0)
            const lineColor = colorScheme[index]
            const res = { label: mainCity, data: sortCitySales, borderColor: lineColor, borderWidth: 2, tension: 0.1, totalSum: totalSum }
            managersTotalSum.push(res)
        })

        return { managersTotalSum }
    }
)