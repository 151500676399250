import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import EditTableDoctorsBonusList from '../views/EditTableDoctorsBonusList';
import { orderListAllSelector } from '../data/selectors/orderListItemsSelector';
import { loadStartAction } from '../data/actions/loadStartActions';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { ACTIVE_PROMOCODE_STATUS } from '../data/constants/statuses';
import { activatedPromocodeBonusListSelector, newPromocodeBonusListSelector } from '../data/selectors/promocodeSelector';
import ViewTableDoctorsBonusList from './../views/ViewTableDoctorsBonusList';
import { loadManagerPromocodeBonusList, loadAllManagersPromocodeBonusListAction } from '../data/actions/promocodeActions';
import AdminViewTableDoctorsBonusList from './../views/AdminViewTableDoctorsBonusList';
import { adminManagerSalesFinLimitSelector, allManagerListSelector } from './../data/selectors/managerListSelector';
import { loadManagerListAction } from './../data/actions/managerListActions';
import { adminDataSelector } from './../data/selectors/userDataSelector';
import { addUserLogDateApi } from '../api/logDataApi';

const AdminDoctorsBonusReportContainer = () => {

    const dispatch = useDispatch()

    const userData = useSelector(userDataSelector)
    const adminData = useSelector(adminDataSelector)
    const currentMonth = userData.settingsData.monthData
    const currentYear = userData.settingsData.yearData
    const [month, setMonth] = useState(currentMonth)
    const [year, setYear] = useState(currentYear)
    const [managerId, setManagerId] = useState(0)
    let locationLang = JSON.parse(localStorage.getItem('location'));
    const [location, setLocation] = useState(locationLang ? locationLang : 'uk-UA');
    const [archiveStatus, setArchiveStatus] = useState(true);
    const activatedPromocodeBonusList = useSelector(activatedPromocodeBonusListSelector(archiveStatus))
    const managerList = useSelector(allManagerListSelector)
    const adminManagerSalesFinLimit = useSelector(adminManagerSalesFinLimitSelector(month, year, managerId))


    const handleChangeMonth = (event) => {
        setMonth(event.target.value)
    }
    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }
    const handleChangeManager = (event) => {
        setManagerId(event.target.value)
    }
    const handleChangeArchive = (event) => {
        setArchiveStatus(event.target.value)
    }

    useEffect(() => {
        dispatch(loadManagerListAction(userData, location))
        localStorage.setItem('location', JSON.stringify(location));
        dispatch(loadAllManagersPromocodeBonusListAction(userData, year, month, managerId, location))
        addUserLogDateApi(userData, '/admin/doctorsBonusReport')
    }, [month, year, managerId, location, archiveStatus])

    return (
        <AdminViewTableDoctorsBonusList
            items={activatedPromocodeBonusList}
            month={month}
            year={year}
            managerId={managerId}
            managerList={managerList}
            location={location}
            setLocation={setLocation}
            archiveStatus={archiveStatus}
            adminData={adminData}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
            handleChangeManager={handleChangeManager}
            handleChangeArchive={handleChangeArchive}
        />

    )
}

export default AdminDoctorsBonusReportContainer
