export const CHART_DATA_LOAD_SUCCESSFUL = 'CHART_DATA_LOAD_SUCCESSFUL'

const initialState = {
    items: []
}

export const chartDataReducer = (state = initialState, {type, payload}) => {
    switch (type) {
    case CHART_DATA_LOAD_SUCCESSFUL:
        return {...state, items: payload}
    default:
        return state
    }
}

export default chartDataReducer
