import history from '../../history';
import { LOGIN_SUCCESS } from '../reducers/authenticationReducer';
import { loginApi } from './../../auth/loginApi';

export const loginAction = (username, password) => {
    return async (dispatch) => {
        const res = await loginApi(username, password)
        const roleName = res.data.user.role.name;
        if (res.status === 200) {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            })
            roleName === 'regman' || roleName === 'admin' || roleName === 'localAdmin' ? history.push('/admin/dashboard') : history.push('/');
        }
    }
}