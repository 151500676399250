import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { de } from 'date-fns/locale';
import { makeStyles } from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
    position: 'relative',
    top: -11
  },
  container: {
    maxHeight: 1000,
    minWidth: 800,
    padding: '0 0 10px',
    overflow: 'visible',
    marginTop: 122
  },
  stickyHeader: {
    backgroundColor: '#e8ebf7',
    position: "sticky",
    top: 112,
    zIndex: 100,
    boxShadow: '1px 1px 1px #ccc'
  },
  formControl: {
    width: 140,
    marginLeft: 10
  },
  adminOrdersSumStyle: {
    width: 250,
    marginLeft: 10,
    position: "relative",
    top: 17
  },
  filterRow: {
    backgroundColor: '#e8ebf7',
    width: '100%',
    display: 'inline-block',
    position: 'relative',
    top: 64,
    zIndex: 101,
    borderBottom: '1px solid #ccc'
  },
});

const AdminViewManagerListChart = (props) => {

  const classes = useStyles();

  const { chartData, period, year, isAdmin, handleChangePeriod, handleChangeYear } = props;
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
  );

  const [isHovered, setIsHovered] = useState(false)

  const options = {
    type: 'line',
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          sort: (a, b, chartData) => {
            return chartData.datasets[b.datasetIndex].totalSum - chartData.datasets[a.datasetIndex].totalSum
          }
        },
        onHover: (event, chartElement) => {
          const datasetIndex = chartElement.datasetIndex
          event.chart.config.data.datasets[datasetIndex].borderWidth = 5
          setIsHovered(true)
        },
        onLeave: (event, chartElement) => {
          const datasetIndex = chartElement.datasetIndex
          const oldBorderWidth = chartData.managersTotalSum[datasetIndex].borderWidth
          event.chart.config.data.datasets[datasetIndex].borderWidth = oldBorderWidth
          setIsHovered(false)
        },
        onClick: null
      },
      title: {
        display: true,
        text: 'График продаж',
      },
      tooltip: {
        enabled: true,
        position: 'nearest',
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          parser: period === 6 ? 'MM' : `${year}-MM-dd`,
          tooltipFormat: period === 6 ? 'M' : `dd-MM-${year}`,
          unit: period === 6 ? 'month' : 'week',
          // unitStepSize: 5,
          displayFormats: period === 6 ? {
            'month': `MM-${year}`
          } :
            { 'week': `dd-MM-${year}` },
        },
      },
      y: {
        ticks: {
          display: false,
          beginAtZero: true,
        },
      }
    },
    // adapters: {
    //   date: {
    //     locale: 'de-DE'
    //   }
    // }
  }

  useEffect(() => {
  }, [isHovered])

  const data = {
    datasets: chartData.managersTotalSum,
  };


  return (
    <>
      <div className={classes.filterRow}>
        {/* <FormControl className={classes.formControl}>
          <InputLabel id="period label">Период</InputLabel>
          <Select
            value={period}
            onChange={handleChangePeriod}
          >
            <MenuItem value={6}>год (по месяцам)</MenuItem>
          </Select>
        </FormControl> */}
        <FormControl className={classes.formControl}>
          <InputLabel id="year label">Год</InputLabel>
          <Select
            value={year}
            onChange={handleChangeYear}
          >
            <MenuItem value={2024}>2024</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={classes.container}>
        <Line options={options} data={data} width={"1000px"} height={"400px"} />
      </div>
    </>
  )
}

export default AdminViewManagerListChart