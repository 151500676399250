import { createSelector } from 'reselect'
import { managerListSelector } from './managerListSelector';
import { promocodeListSelector } from './promocodeSelector';

export const orderListItemsSelector = (state) => state.orderList.items
export const currentMonthSelector = (state) => state.orderList.currentMonth

export const orderListAllSelector = createSelector(
    [orderListItemsSelector, promocodeListSelector],
    (items, promocodeList) => {
        if (items) {
            const arrays = Object.values(items)
            var res = [].concat.apply([], arrays);

            let sortedOrders = [...res];
            sortedOrders.sort((b, a) => {
                if (Number(a.id) < Number(b.id)) {
                    return -1;
                }
                if (Number(a.id) > Number(b.id)) {
                    return 1;
                }
                return 0;
            });
            return sortedOrders.map(order => {
                const promocode = promocodeList.find(p => {
                    return p.promocode === order.promocode
                })
                const promocodeDoctor = promocode ? promocode.doctor : '-'
                return { ...order, doctorName: promocodeDoctor };
            });
        }
    }
)

export const adminOrdersSumSelector = createSelector(
    [orderListAllSelector],
    (items) => {
        const ordersSum = items.reduce((sum, i) => i.productTotalPrice && i.cluster_to !== 'manual' ? sum + i.productTotalPrice : sum + i.totalPrice, 0)
        return ordersSum
    }
)



export const managerSaleSumMonthSelector = createSelector(
    [orderListItemsSelector],
    (items) => {
        if (items) {
            // console.log("🚀 ~ file: orderListItemsSelector.js ~ line 21 ~ items", items)

            // const prepareOrderMonth = (orderDateString) => {
            //     const orderDate = date.parse(orderDateString, 'YYYY-MM-DD HH:mm:ss');
            //     return orderDate.getMonth() + 1
            // }
            let managerSaleSumMonth = {}
            Object.keys(items).forEach(monthNumber => {
                const monthOrderList = items[monthNumber]
                const itemsMonthSum = {}
                // const itemsMonthRow = {
                //     'Антон Г.': 100,
                //     'Оля Г.': 200,
                // }
                // let monthSum = 0
                // let managerMonthSum = 0
                monthOrderList.forEach(order => {
                    const manager = order.managerName
                    itemsMonthSum[manager] = itemsMonthSum[manager]
                        ? itemsMonthSum[manager] + order.total_default
                        : order.total_default
                })
                managerSaleSumMonth[monthNumber] = itemsMonthSum
                // const row = {manager, [monthNumber]: itemsMonthSum[manager]}
                console.log("🚀 ~ file: orderListItemsSelector.js ~ line 57 ~ Object.keys ~ managerSaleSumMonth[monthNumber]", managerSaleSumMonth[monthNumber])
                // console.log("🚀 ~ file: orderListItemsSelector.js ~ line 57 ~ Object.keys ~ itemsMonthSum", itemsMonthSum)
                //  managerListMonthSum.push()
            })

            // console.log("🚀 ~ file: orderListItemsSelector.js ~ line 50 ~ itemsMonthSum", itemsMonthSum)
            return managerSaleSumMonth
        }
    }
)

export const managerSaleTableSelector = createSelector(
    [managerSaleSumMonthSelector, managerListSelector],
    (managerSaleSumMonth, managerList) => {
        // console.log("🚀 ~ file: orderListItemsSelector.js ~ line 74 ~ managerSaleSumMonth", managerSaleSumMonth)
        // if (!managerSaleSumMonth) return
        const res = Object.keys(managerList).map(managerId => {
            const fullName = managerList[managerId].fullName
            const bonusPercent = managerList[managerId].bonusPercent
            let row = { fullName }
            Object.keys(managerSaleSumMonth).forEach(monthNumber => {
                const managerSumList = managerSaleSumMonth[monthNumber]
                // console.log("🚀 ~ file: orderListItemsSelector.js ~ line 68 ~ Object.keys ~ managerSumList", managerSumList)
                row[monthNumber] = managerSumList[fullName]
                // console.log("🚀 ~ file: orderListItemsSelector.js ~ line 70 ~ Object.keys ~ managerId", managerId)
            })
            // console.log("🚀 ~ file: orderListItemsSelector.js ~ line 71 ~ res ~ row", row)
            return row
        })
        // console.log("🚀 ~ file: orderListItemsSelector.js ~ line 73 ~ res", res)
        return res
    }
)