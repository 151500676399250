import { AppBar, makeStyles, Toolbar, Typography, Button, Link, Divider } from "@material-ui/core"
import DashboardManagerSalesContainer from './../containers/DashboardManagerSalesContainer';
import { useSelector } from 'react-redux';
import { userDataSelector } from "../data/selectors/userDataSelector";
import HeaderLayout from "../layouts/HeaderLayout";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    managerName: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    link: {
        color: '#ffffff'
    }
}));

const DashboardPage = () => {
    const classes = useStyles();

    const userData = useSelector(userDataSelector)

    const preventDefault = (event) => event.preventDefault();

    return (
        <>
            <HeaderLayout />
            <DashboardManagerSalesContainer />
        </>
    )
}

export default DashboardPage