import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { adminOrdersSumSelector, currentMonthSelector, managerListFromOrderListSelector, orderListAllSelector } from './../data/selectors/orderListItemsSelector';
import ViewTableOrderList from './../views/ViewTableOrderList';
import { loadOrderListByMonthAction, loadMonthOrderListAction, loadAdminMonthOrderListAction } from './../data/actions/orderListActions';
import { userDataSelector } from '../data/selectors/userDataSelector';
import AdminViewTableOrderList from './../views/AdminViewTableOrderList';
import { loadManagerListAction } from './../data/actions/managerListActions';
import { managerListSelector, allManagerListSelector } from './../data/selectors/managerListSelector';
import { adminDataSelector } from './../data/selectors/userDataSelector';
import { addUserLogDateApi } from '../api/logDataApi';

const AdminOrderListPerMonthContainer = () => {


    const dispatch = useDispatch()

    const userData = useSelector(userDataSelector)
    const adminData = useSelector(adminDataSelector)

    const currentMonth = userData.settingsData.monthData
    const currentYear = userData.settingsData.yearData
    const [month, setMonth] = useState(currentMonth)
    const [year, setYear] = useState(currentYear)
    const [managerId, setManagerId] = useState(0)
    const [source, setSource] = useState('all')
    let locationLang = JSON.parse(localStorage.getItem('location'));
    const [location, setLocation] = useState(locationLang ? locationLang : 'uk-UA');

    const orders = useSelector(orderListAllSelector)
    const managerList = useSelector(allManagerListSelector)
    const adminOrdersSum = useSelector(adminOrdersSumSelector)

    const handleChangeMonth = (event) => {
        setMonth(event.target.value)
    }
    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }
    const handleChangeManager = (event) => {
        setManagerId(event.target.value)
    }
    const handleChangeSource = (event) => {
        setSource(event.target.value)
    }

    useEffect(() => {
        dispatch(loadAdminMonthOrderListAction(userData, year, month, managerId, location, source))
        dispatch(loadManagerListAction(userData, location))
        localStorage.setItem('location', JSON.stringify(location));
        addUserLogDateApi(userData, '/admin/orderListReport')
    }, [month, year, managerId, location, source])

    return (
        // <div>111</div>
        <AdminViewTableOrderList
            items={orders}
            month={month}
            year={year}
            managerId={managerId}
            managerList={managerList}
            location={location}
            setLocation={setLocation}
            adminData={adminData}
            adminOrdersSum={adminOrdersSum}
            source={source}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
            handleChangeManager={handleChangeManager}
            handleChangeSource={handleChangeSource}
        />
    )
}

export default AdminOrderListPerMonthContainer
